import React from "react";
import SEO from "../components/seo";
import Layout from "../components/Layout/Layout";
import { graphql, useStaticQuery } from "gatsby";
import BlockContent from "../components/BlockContent/BlockContent";
import { Heading1 } from "../components/Typography/Typography";

const PartnerPage = ({ location }) => {
  const {
    sanityPage: { title, content },
  } = useStaticQuery(
    graphql`
      {
        sanityPage(
          slug: { current: { regex: "/^partner/" } }
          mainPage: { eq: true }
        ) {
          content: _rawContent(resolveReferences: { maxDepth: 5 })
          title
        }
      }
    `
  );
  return (
    <Layout location={location} standardPadding maxWidth>
      <SEO title={title} />
      <Heading1>{title}</Heading1>
      <br />
      <BlockContent blocks={content} />
    </Layout>
  );
};

export default PartnerPage;
